import React from 'react';
import { Container } from 'theme-ui';
import SEO from '../components/seo';
import PrimaryLayout from '../components/layout/primary/primary';

const refundPage: React.FC<{}> = () => (
	<PrimaryLayout>
		<SEO title="Refund and returns" />
		<Container py="40px">
			<h1> Our Return Policy</h1>
			<p>We offer high quality product and stive to provide best customer experience as possible
			IndusFoods.US makes every attempt to make sure that customers are happy with their purchase. If you receive an item different than what was described or was damaged, we will gladly work with you for a return or exchange.
			We strive to offer 100% satisfaction guaranteed.</p>
			<h2>General Returns Information</h2>
			<p>If you receive an item different than what was described or was damaged, please contact us directly within 24 hrs of receiving the shipment to report the defect or damage. You should be able to reach an agreement with one another for an exchange or refund.</p>

			<p>If you have any issue with your order, please contact us within 24 hours of receipt of your order. You can find the email address and phone number on the invoice. When writing to arrange for its return, please be as descriptive as possible regarding the problem with the merchandise.</p>

			<p>To be eligible for a refund, you must return your item in the same condition you received it once we agree that the item can be returned.</p>

			<p>Shipping and handling charges for returning items are borne by the buyer (unless the item was damaged). If an item was shipped FREE of Charge, upon return, you may be charged for the original shipping.</p>

			<p>Unfortunately, any custom made, personalized or perishable items are not eligible for a return or exchange. Any consumable item cannot be returned after the original packaging has been opened or the factory seal is broken.</p>

			<p>NOTE: ANY RETURN / EXCHANGE REQUEST RECEIVED AFTER 7 DAYS OF RECEIPT SHALL NOT BE ELIGIBLE FOR A RETURN / EXCHANGE.</p>

			<h2>Refund Policy</h2>
			<p>IndusFoods.US can create full or partial refunds for buyers, including original shipping costs.</p>

			<p>Refunds are typically processed within one week too two weeks’ time. The funds will be deposited back in your credit card account, and IndusFoods.US will send you confirmation of the refund via e-mail. It may take additional 3 to 4 days for the refund to appear on your statement.</p>

			<p>Note: All charges on your Credit Card Statement will appear as Krish Enterprises LLC</p>
		</Container>	
	</PrimaryLayout>
);

export default refundPage;
